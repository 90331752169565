<template>
  <vx-card style="padding-left: 10px">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        search
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="title">Push Notification Title</vs-th>
          <vs-th sort-key="type">Type</vs-th>
          <vs-th sort-key="slug">Slug</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].title">
              <p v-if="data[indextr].title === ''">Notification Title</p>
              <div v-else>{{ data[indextr].title| capitalize }}</div>
            </vs-td>
            <vs-td :data="data[indextr].type">
              <p v-if="data[indextr].type === ''">Notification Type</p>
              <div v-else>{{ data[indextr].type | capitalize }}</div>
            </vs-td>
            <vs-td :data="data[indextr].slug">
              <p v-if="data[indextr].slug === ''">Notification Slug</p>
              <div v-else>{{ data[indextr].slug | capitalize }}</div>
            </vs-td>
            <vs-td>{{data[indextr].createdAt | date_formatter}}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
            <!--
                      <vs-td :data="data[indextr]._id">
                        <vs-radio v-model="radios2" vs-value="Disable" color="danger"></vs-radio>
            </vs-td>-->
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
          }}
          -
          {{
          totalDocs - dataTableParams.page * dataTableParams.limit > 0
          ? dataTableParams.page * dataTableParams.limit
          : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25
      },
      serverResponded: false,
      users: [],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      radios2: "primary",
      awaitingSearch: null
    };
  },
  methods: {
    ...mapActions("email", ["fetchPushNotificationTemplates"]),
    moment(date) {
      return moment(date);
    },
    getEmailsList() {
      let self = this;
      this.fetchPushNotificationTemplates(self.dataTableParams).then(res => {
        console.log('ressss',res.data.data.docs)
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.noDataText = "No Data Available";
        self.page = res.data.data.pagination.page;
      });
      this.serverResponded = true;
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getEmailsList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getEmailsList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getEmailsList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "edit-push-notification-template", params: { id: id } });
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getEmailsList();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getEmailsList();
      }
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  created() {
    this.getEmailsList();
  }
};
</script>
